var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "12",
      "cy": "12",
      "r": "12"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M15.333 9.097c0 1.707-1.498 3.096-3.341 3.096-1.843 0-3.342-1.389-3.342-3.096C8.65 7.389 10.149 6 11.99 6c1.844 0 3.342 1.39 3.342 3.097Zm-1.958 0c0-.707-.62-1.282-1.383-1.282s-1.384.575-1.384 1.282c0 .706.621 1.282 1.383 1.282.764 0 1.384-.576 1.384-1.282ZM13.352 14.72a6.539 6.539 0 0 0 1.94-.745c.458-.267.595-.827.307-1.25-.287-.425-.892-.553-1.35-.286a4.53 4.53 0 0 1-4.499 0c-.458-.267-1.062-.14-1.35.286-.287.424-.15.983.307 1.25a6.55 6.55 0 0 0 1.94.745L8.78 16.451a.861.861 0 0 0 0 1.283c.192.177.442.266.693.266.25 0 .501-.089.693-.266L12 16.033l1.836 1.701a1.034 1.034 0 0 0 1.384 0 .86.86 0 0 0 0-1.283l-1.868-1.73Z",
      "fill": "#fff"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }